import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { UserActions } from "../redux/UserActions";
import { moodColors, moodValues } from "../redux/constants";
import { Link } from "react-router-dom";
import { animated } from "@react-spring/web";
import { ReactComponent as Angry } from "./icons/angry.svg";
import { ReactComponent as Excited } from "./icons/excited.svg";
import { ReactComponent as Meh } from "./icons/meh.svg";
import { ReactComponent as Happy } from "./icons/happy.svg";
import { ReactComponent as Proud } from "./icons/proud.svg";
import { ReactComponent as Sad } from "./icons/sad.svg";
import { ReactComponent as Stressed } from "./icons/stressed.svg";
import { ReactComponent as Well } from "./icons/well.svg";
import { ReactComponent as Worried } from "./icons/worried.svg";
import {
  BriefcaseIcon,
  HomeIcon,
  LocationMarkerIcon,
  UserIcon,
} from "@heroicons/react/solid";
import MultiSelectListBox from "./Helper/MultiSelectListBox";

const negativeMoods = ["Angry", "Stressed", "Sad", "Worried", "Meh"];
const positiveMoods = ["Excited", "Happy", "Well", "Proud"];

function mapRange(value, in_min, in_max, out_min, out_max) {
  return ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export default function Dashboard() {
  const userState = useSelector((state) => state.user);
  const organization = useSelector((state) => state.user.organization);
  const dispatch = useDispatch();

  const [selectedMoods, setSelectedMoods] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [loading, setLoading] = useState(true);
  const [moods, setMoods] = useState([]);

  useEffect(() => {
    let tempMoods = userState.lastMoods;
    if (selectedMoods.length > 0)
      tempMoods = tempMoods.filter((mood) =>
        selectedMoods.includes(moodValues[mood.mood])
      );
    if (selectedCategories.length > 0)
      tempMoods = tempMoods.filter((mood) =>
        selectedCategories.some((item) => mood.categories.includes(item))
      );
    if (selectedLocations.length > 0)
      tempMoods = tempMoods.filter((mood) =>
        selectedLocations.some((item) => mood.user.locations.includes(item))
      );
    if (selectedDepartments.length > 0)
      tempMoods = tempMoods.filter((mood) =>
        selectedDepartments.some((item) => mood.user.departments.includes(item))
      );
    if (selectedName.length > 0)
      tempMoods = tempMoods.filter((mood) =>
        (mood.user.anonymous
          ? `${mood.user.userName}`
          : `${mood.user.firstName}${mood.user.lastName}`
        )
          .toLowerCase()
          .includes(String(selectedName).toLowerCase())
      );

    setMoods(
      [...tempMoods].sort((a, b) =>
        new Date(a.date.$date) < new Date(b.date.$date) ? 1 : -1
      )
    );
  }, [
    userState.lastMoods,
    selectedCategories,
    selectedDepartments,
    selectedLocations,
    selectedMoods,
    selectedName,
  ]);

  useEffect(
    () =>
      dispatch(
        UserActions.getLastMoods({ code: userState.selectedOrganization })
      ),
    []
  );
  useEffect(
    () =>
      dispatch(
        UserActions.getMoodScore({
          organization: userState.selectedOrganization,
        })
      ),
    []
  );
  useEffect(
    () => setLoading(userState.status.lastMoods === "pending"),
    [userState.messages.lastMoods]
  );

  return (
    <div className="flex flex-col w-full h-screenbg-gray-100 dark:bg-gray-900 overflow-hidden">
      <div className="grid xl:grid-cols-6 gap-4 p-4">
        <div className="flex flex-col col-span-2 bg-white flex-grow justify-center items-center p-4 gap-2 rounded-md shadow-lg ">
          <span className="flex w-full items-center gap-x-1">
            <label className="font-semibold w-24">Name:</label>
            <input
              type="text"
              placeholder="Enter Name..."
              className="flex-grow h-10 rounded-md shadow border-gray-200"
              value={selectedName}
              onChange={(e) => setSelectedName(e.target.value)}
            />
          </span>
          <span className="flex flex-grow w-full items-center gap-x-1">
            <label className="font-semibold w-24">Department:</label>
            <MultiSelectListBox
              options={
                ["Support"].includes(userState.user.role)
                  ? userState.user.departments
                  : organization?.departments
              }
              defaultValues={selectedDepartments}
              handleSelected={(e) => setSelectedDepartments(e)}
            />
          </span>
          <span className="flex w-full items-center gap-x-1">
            <label className="font-semibold w-24">Location:</label>
            <MultiSelectListBox
              options={
                ["Support"].includes(userState.user.role)
                  ? userState.user.locations
                  : organization?.locations
              }
              defaultValues={selectedLocations}
              handleSelected={(e) => setSelectedLocations(e)}
            />
          </span>
          <span className="flex w-full items-center justify-between gap-x-1">
            <label className="font-semibold w-24">Categories:</label>
            <MultiSelectListBox
              className="flex-grow"
              options={["Personal", "Work", "Family"]}
              defaultValues={selectedCategories}
              handleSelected={(e) => setSelectedCategories(e)}
            />
          </span>
        </div>
        <div className="relative hidden xl:flex h-56 col-span-2 flex-col bg-white p-4 rounded-md shadow-lg">
          <label className="w-full text-center font-bold text-2xl">
            Latest Moods
          </label>
          <MoodChart
            moods={userState.lastMoods}
            selectedMoods={selectedMoods}
            filterMoods={(e) => setSelectedMoods([e.id])}
          />
          <span className="absolute left-4 bottom-4">
            <label
              className="p-2 bg-gray-100 rounded-md font-semibold hover:bg-gray-200 cursor-pointer"
              onClick={() => setSelectedMoods([])}
            >
              Clear Moods
            </label>
          </span>
        </div>
        <div className="hidden xl:flex col-span-2 flex-col bg-white p-4 rounded-md shadow-lg">
          <ScoreChart
            moodScore={userState.moodScore}
            target={organization?.targetScore || 70}
          />
        </div>
      </div>
      <div className="flex flex-wrap py-4 gap-4 justify-center overflow-y-auto">
        {moods.length === 0 && loading ? (
          <span>
            <label className="flex text-xl font-semibold">Loading...</label>
          </span>
        ) : (
          moods.map((mood) => <MoodCard mood={mood} />)
        )}
      </div>
    </div>
  );
}

function MoodCard({ mood }) {
  return (
    <div
      className={
        "flex flex-col gap-y-1 w-72 pb-4 bg-white  shadow-lg rounded-md"
      }
    >
      <Link
        to={`/users/${mood.user._id.$oid}`}
        className={
          "flex flex-col h-16 p-2 items-center justify-center rounded-t-md shadow-lg cursor-pointer " +
          `bg-${moodValues[mood.mood]}`
        }
      >
        <label className="w-full pointer-events-none text-center font-semibold text-white text-lg">
          {mood.user.anonymous
            ? `${mood.user.userName}`
            : `${mood.user.firstName} ${mood.user.lastName}`}
        </label>
        <label
          className={
            "w-full h-5 pointer-events-none text-center font-semibold text-sm " +
            (mood.answered === true
              ? "text-green-800"
              : mood.priorities.length > 0
              ? "text-red-800"
              : "")
          }
        >
          {mood.priorities.length > 0 ? "Priority" : ""}
        </label>
      </Link>
      <span className="flex items-center w-full gap-x-4 px-4 pt-4">
        {moodValues[mood.mood] === "Angry" && (
          <Angry className="h-12 w-12 fill-current text-Angry" />
        )}
        {moodValues[mood.mood] === "Excited" && (
          <Excited className="h-12 w-12 fill-current text-Excited" />
        )}
        {moodValues[mood.mood] === "Happy" && (
          <Happy className="h-12 w-12 fill-current text-Happy" />
        )}
        {moodValues[mood.mood] === "Meh" && (
          <Meh className="h-12 w-12 fill-current text-Meh" />
        )}
        {moodValues[mood.mood] === "Proud" && (
          <Proud className="h-12 w-12 fill-current text-Proud" />
        )}
        {moodValues[mood.mood] === "Sad" && (
          <Sad className="h-12 w-12 fill-current text-Sad" />
        )}
        {moodValues[mood.mood] === "Stressed" && (
          <Stressed className="h-12 w-12 fill-current text-Stressed" />
        )}
        {moodValues[mood.mood] === "Well" && (
          <Well className="h-12 w-12 fill-current text-Well" />
        )}
        {moodValues[mood.mood] === "Worried" && (
          <Worried className="h-12 w-12 fill-current text-Worried" />
        )}
        <span className="flex flex-col">
          <label className="font-semibold">{moodValues[mood.mood]}</label>
          {moodValues[mood.mood] !== "Meh" && (
            <span className="w-32 h-3 rounded border-2 border-gray-200 relative">
              {mood.scale === 1 && (
                <span className="absolute h-2 rounded-l bg-red-400 w-0" />
              )}
              {mood.scale === 2 && (
                <span className="absolute h-2 rounded-l bg-yellow-600 w-8" />
              )}
              {mood.scale === 3 && (
                <span className="absolute h-2 rounded-l bg-yellow-400 w-16" />
              )}
              {mood.scale === 4 && (
                <span className="absolute h-2 rounded-l bg-green-400 w-24" />
              )}
              {mood.scale === 5 && (
                <span className="absolute h-2 rounded bg-green-600 w-32" />
              )}
            </span>
          )}
          <label>{new Date(mood?.date?.$date).toLocaleString()}</label>
        </span>
      </span>
      <span className="flex flex-grow items-center gap-x-2 px-4">
        <BriefcaseIcon className="w-8 h-8 fill-current text-gray-500" />
        <label className="font-semibold w-full truncate">
          {mood.user.departments.join(", ") || "No Department"}
        </label>
      </span>
      <span className="flex items-center gap-x-2 px-4">
        <LocationMarkerIcon className="w-8 h-8 fill-current text-gray-500" />
        <label className="font-semibold w-full truncate">
          {mood.user.locations.join(", ") || "No Location"}
        </label>
      </span>
      <span className="flex gap-x-2 items-center px-5">
        <label className="text-lg font-semibold">Categories:</label>
        {mood.categories.length === 0 && <label>None Selected</label>}
        {mood.categories.length > 0 && (
          <span className="flex h-6">
            {mood.categories.includes("Family") && (
              <HomeIcon className="w-6 h-6 fill-current text-gray-500" />
            )}
            {mood.categories.includes("Work") && (
              <BriefcaseIcon className="w-6 h-6 fill-current text-gray-500" />
            )}
            {mood.categories.includes("Personal") && (
              <UserIcon className="w-6 h-6 fill-current text-gray-500" />
            )}
          </span>
        )}
      </span>
    </div>
  );
}

function MoodChart({ moods, filterMoods, selectedMoods }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempData = [];
    let isBig = true; // Toggle for big and small percentages

    for (const [key, value] of Object.entries(moodValues)) {
      const count = moods.filter(
        (mood) => String(mood.mood) === String(key)
      ).length;

      if (count > 0) {
        tempData.push({
          label: key,
          value: count,
          id: value,
          color: moodColors[value],
          fontSize: isBig ? 18 : 12, // Alternate font size
          fontWeight: isBig ? 700 : 500, // Alternate font weight
        });

        isBig = !isBig; // Toggle for the next label
      }
    }

    setData(tempData);
  }, [moods]);

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 35, right: 80, bottom: 35, left: 80 }}
      innerRadius={0.5}
      padAngle={2}
      cornerRadius={5}
      colors={{ datum: "data.color" }}
      arcLinkLabelsSkipAngle={11}
      arcLinkLabelsTextColor="#000000"
      arcLinkLabelsThickness={2}
      arcLinkLabelsTextOffset={0.1}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={15}
      arcLinkLabelComponent={({ datum, label, style }) => (
        <animated.g
          transform={style.textPosition}
          style={{ pointerEvents: "none" }}
        >
          <text
            textAnchor="middle"
            dominantBaseline="central"
            fill={datum.color}
            style={{ fontSize: 16, fontWeight: 600 }}
          >
            {label}
          </text>
        </animated.g>
      )}
      arcLabelsTextColor="#000000"
      activeOuterRadiusOffset={15}
      onClick={(e) => filterMoods(e)}
      arcLabelsComponent={({ label, style }) => (
        <animated.g
          transform={style.transform}
          style={{ pointerEvents: "none" }}
        >
          <text
            textAnchor="middle"
            dominantBaseline="central"
            fill={style.textColor}
            style={{ fontSize: 15, fontWeight: 600 }}
          >
            {label}
          </text>
        </animated.g>
      )}
    />
  );
}

function ScoreChart({ moodScore, target }) {
  target = parseInt(target);
  return (
    <ResponsiveRadialBar
      data={[
        {
          id: "moodScore",
          data: [{ x: "MoodScore", y: moodScore, color: "#4CAF50" }],
        },
      ]}
      margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
      colors={{ datum: "data.color" }}
      maxValue={100}
      valueFormat=">-.2f"
      startAngle={-135}
      endAngle={135}
      innerRadius={0.7}
      padding={0.15}
      cornerRadius={20}
      enableRadialGrid={false}
      enableLabels={false}
      radialAxisStart={false}
      circularAxisOuter={{
        tickComponent: (value) => {
          if (parseInt(value.label) === target) {
            const val = mapRange(value.label, 0, 100, 0, 360);
            const angle = val;
            const x2 = 0;
            const y2 = 100;
            return (
              <g transform={`rotate(${angle}, ${0}, ${0})`}>
                <g transform={`rotate(${-angle}, ${0}, ${y2 + 8})`}>
                  <text
                    x={0}
                    y={target < 30 || target > 70 ? y2 + -10 : y2 + 30}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="black"
                    font-weight="bold"
                  >
                    {target}%
                  </text>
                </g>
                <line
                  x1={0}
                  y1={y2 - 30}
                  x2={x2}
                  y2={y2 - 20}
                  stroke="#F44336"
                  strokeWidth={4}
                  strokeLinecap="round"
                />
              </g>
            );
          }
          return null;
        },
      }}
      isInteractive={false}
      enableTracks={false}
      layers={["grid", "tracks", "bars", CenteredMetric]}
    />
  );
}

const CenteredMetric = ({ center, bars }) => {
  let total = Math.round(bars[0].value);

  return (
    <g>
      <text
        x={center[0]}
        y={center[1]-10}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: "28px", fontWeight: 700 }}
      >
        {`${total}%`}
      </text>
      <text
        x={center[0]}
        y={center[1] + 14}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: "14px", fontWeight: 700 }}
      >
        Mood Score
      </text>
      <text
        x={center[0]}
        y={center[1] + 26}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: "12px", fontWeight: 600 }}
      >
        All time
      </text>
    </g>
  );
};
